@mixin flows-theme($theme) {

    .home-loading-text {
      font-size: 24px;
      font-weight: bold;
      color: mat-color($fuse-navy, 900) !important;
    }

    .home-loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      font-family: Arial, sans-serif;
    }

    .home-spinner {
        margin-left: 10px;
        border: 4px solid mat-color($fuse-navy, 900);
        border-left-color: mat-color($fuse-navy, 200);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        animation: spin 1s linear infinite;
    }


    .snackbar-warning {
      background-color: mat-color($default-warn-palette) !important;
      color: #fff !important;
    }

    .snackbar-warning button {
      background-color: mat-color($default-warn-palette) !important;
      color: whitesmoke !important;
    }

    .snackbar-info {
      background-color: #579811 !important;
      color: #fff !important;
    }

    .snackbar-info button {
      background-color: #579811 !important;
      color: whitesmoke !important;
    }

    .json-obj {
      font-family: 'Courier New', Courier, monospace;
      font-size: 14px;
      line-height: 1.5;
      padding: 10px;
      border: none;
      background-color: #f6f8fa;
      color: #333;
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    .notes {
      text-align: justify;
      margin-top: 6px !important;
      margin-bottom: 16px !important;
      font-weight: 300 !important;
      background-color: rgb(255, 255, 234);
      padding: 15px !important;
      padding-left: 25px !important;
      padding-right: 25px !important;
      border-radius: 5px !important;
      border: 1px solid #ccc;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3), -2px -2px 5px rgba(255, 255, 255, 0.5);
    }

    .notes-warn {
      text-align: justify;
      margin-top: 6px !important;
      margin-bottom: 16px !important;
      font-weight: 300 !important;
      background-color: rgb(255, 217, 217);
      padding: 15px !important;
      padding-left: 25px !important;
      padding-right: 25px !important;
      border-radius: 5px !important;
      border: 1px solid #ccc;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3), -2px -2px 5px rgba(255, 255, 255, 0.5);
    }

    .acordeon-title {
        color: mat-color($fuse-navy, 900) !important;
        font-weight: bold !important;
        font-size: 14px !important;
        margin-top: 2px !important;
    }
    .fila-color {
      // background-color: rgb(255, 221, 224);
      background-color: mat-color($fuse-navy, 50) !important;
    }
    .number-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: mat-color($fuse-navy, 900) !important;
        color: #fff;
        font-weight: bold;
        font-size: 10px;
        line-height: 1;
        padding: 5px !important;
      }

      ._table-header {
        background-color: mat-color($fuse-navy, 700, 900) !important;
        color: whitesmoke !important;
        height: 35px !important;
      }
      .table-record {
        height: 30px !important;
        cursor:pointer !important;
      }
      .table-record-move {
        height: 30px !important;
        caret-color: red !important;
        cursor: move !important;
      }
      .table-record-delete {
        height: 30px !important;
        color: red !important;
        cursor: pointer !important;
        padding-left: 15px !important;
        text-align: center !important;
      }
      .flow-record-icon {
        color: mat-color($fuse-navy, 900) !important;
        font-size: 20px !important;
      }

      .flow-record-icon-red-small {
        color: #c43a18 !important;
        font-size: 20px !important;
      }
      .flow-record-icon-red {
        color: #c43a18 !important;
      }
      .published {
        font-size: 10px !important;
        padding: 4px 9px !important;
        min-height: 18px;
        background-color: #579811 !important;
        color: whitesmoke !important;
    }
    .non-published {
        font-size: 10px !important;
        padding: 4px 9px !important;
        min-height: 18px;
        background-color: #c43a18 !important;
    }
    .icon-edition {
      color: mat-color($fuse-navy, 900) !important;
      font-size: 18px !important;
    }

    .icon-svg {
      cursor: pointer;      
      fill: map-get($fuse-navy, 900); /* Esto hará que el color del SVG sea igual al color del texto circundante */
      transition: fill 0.3s ease; /* Agrega una transición suave al color */
    }
    
    .icon-svg:hover {
      cursor:pointer;      
      fill: map-get($fuse-navy, 300); /* Cambia el color cuando el usuario pasa el ratón sobre el SVG */
    }
}