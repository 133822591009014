// Import Fuse core library
@import "@fuse/scss/core";
// Import app.theme.scss
@import "app/app.theme";
// @import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
// @import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
// @import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
// @import "../node_modules/ag-grid-community/dist/styles/ag-theme-material.css";
// @import "../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "primeng/resources/themes/saga-blue/theme.css";
@import "primeng/resources/primeng.min.css";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import "primeflex/primeflex.css";

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.ag-header-cell-label {
  text-align: center;
}

.ui-datatable,
.ui-datatable .ui-datatable-tablewrapper table {
  font-size: 12px !important;
}

.ql-editor {
  height: 250px;
}