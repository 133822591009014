@mixin form-detail-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    #form-detail {
        .sidebar {
            .steps {
                .step {
                    color: map-get($foreground, secondary-text);
                    &.current {
                        @if ($is-dark) {
                            background: map-get($background, app-bar);
                            color: map-get($foreground, text);
                        }
                        @else {
                            background: map-get($accent, 50);
                            color: map-get($accent, '50-contrast');
                        }
                        .index {
                            span {
                                @if ($is-dark) {
                                    background: map-get($background, app-bar);
                                    border-color: map-get($accent, default);
                                }
                                @else {
                                    background: map-get($background, background);
                                    border-color: map-get($accent, default);
                                }
                            }
                        }
                    }
                    &.completed {
                        color: map-get($foreground, text);
                        .index {
                            span {
                                border-color: map-get($accent, default);
                            }
                            &:after {
                                border-left-color: map-get($accent, default);
                            }
                        }
                        +.step {
                            .index {
                                &:before {
                                    border-left-color: map-get($accent, default);
                                }
                            }
                        }
                    }
                    .index {
                        &:before,
                        &:after {
                            border-left-color: map-get($foreground, divider);
                        }
                        span {
                            background: map-get($background, background);
                            border-color: map-get($foreground, divider);
                        }
                    }
                }
            }
        }
        .center {
            .content {
                @if ($is-dark) {
                    background: #353535;
                }
                @else {
                    background: mat-color($mat-grey, 200);
                }
                .detail-step {
                    width: 100% !important;
                    .detail-step-content {
                        background: map-get($background, card);
                    }
                }
            }
        }
    }
    // Compose dialog
    .mail-compose-dialog {
        .mat-dialog-container {
            .compose-form {
                .attachment-list {
                    .attachment {
                        background-color: map-get($background, card);
                        border-color: map-get($foreground, divider);
                    }
                }
            }
        }
    }
}